import { FC } from 'react';
import useUtilityStyles from '../../Themes/utility.styles';
import { joinArgs } from '../../Utils/arrayUtils';

interface SectionProps {
  title: string;
}

export const DevToolsSection: FC<SectionProps> = (props) => {
  const utilClasses = useUtilityStyles();
  const { title, children } = props;
  return (
    <details open style={{ display: 'block' }} className={joinArgs(utilClasses.box, utilClasses.p2, utilClasses.mb2)}>
      <summary style={{ marginBottom: '1rem', cursor: 'pointer', verticalAlign: 'center' }}>
        <h2 style={{ display: 'inline', margin: 0 }}>{title}</h2>
      </summary>

      <div>
        {children}
      </div>
    </details>
  );
};
