import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { userAcceptTermsOfUse } from '../../Services/UserService';
import { ReduxState } from '../../redux/initialState';
import LoadState from '../../redux/loadState';
import { getCurrentUserAction } from '../../redux/user/userActionCreators';
import { Checkbox } from '../../Shared/fields/Checkbox';

export default function UserSetTermsAccepted(): ReactElement {
  const userState = useSelector((state: ReduxState) => state.user);
  const user = userState.currentUser;
  const dispatch = useDispatch();

  const handleChange = () => {
    if (!user || !user.id) {
      return;
    }
    dispatch(getCurrentUserAction());
    // TODO: userAcceptTermsOfUse(DateTime.local().toFormat('yyyy-MM-dd\'T\'hh:mm:ss')).then(() => {});
  };

  return (
    <div>
      <Checkbox id="accepted-tou" label="Accepted Terms and Conditions" checked={!!user?.acceptedTermsOfUse} onChange={handleChange} />
      {userState.fetchLoadState === LoadState.loading ? <strong>Loading user...</strong> : null}
    </div>
  );
}
