import { faChevronLeft, faChevronRight, faPastafarianism, faHeadSideMask } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { RequisitionStatus } from '../../Data/RequisitionStatus';
import Autocomplete from '../../Shared/Autocomplete/Autocomplete';
import { Callout } from '../../Shared/Callout/Callout';
import { Checkbox } from '../../Shared/fields/Checkbox';
import { LoadingSpinner } from '../../Shared/LoadingSpinner/LoadingSpinner';
import { useButtonStyles } from '../../Themes/button.styles';
import useUtilityStyles from '../../Themes/utility.styles';
import { joinArgs } from '../../Utils/arrayUtils';
import { routes } from '../../Utils/routes';
import { ReqStatusBadge } from '../../Shared/ReqStatusBadge/ReqStatusBadge';
import { DevToolsSection } from './DevToolsSection';

export const DevToolsStyleGuide: FC = () => {
  const buttonClasses = useButtonStyles();
  const utilClasses = useUtilityStyles();

  const autocompleteOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => ({ id: num, name: `asdf${num}` }));
  return (
    <>
      <h1 style={{ fontSize: '2rem' }}>Living Styleguide</h1>
      <DevToolsSection title="Requisition Status Badges">
        <Row>
          <Col xs={2} className={utilClasses.pb1}>
            <ReqStatusBadge status={RequisitionStatus.completed} />
          </Col>
          <Col xs={2} className={utilClasses.pb1}>
            <ReqStatusBadge status={RequisitionStatus.declined} />
          </Col>
          <Col xs={2} className={utilClasses.pb1}>
            <ReqStatusBadge status={RequisitionStatus.returned} />
          </Col>
          <Col xs={2} className={utilClasses.pb1}>
            <ReqStatusBadge status={RequisitionStatus.pendingBuyer} />
          </Col>
          <Col xs={2} className={utilClasses.pb1}>
            <ReqStatusBadge status={RequisitionStatus.pendingFirstApproval} />
          </Col>
          <Col xs={2} className={utilClasses.pb1}>
            <ReqStatusBadge status={RequisitionStatus.pendingSecondApproval} />
          </Col>
          <Col xs={2} className={utilClasses.pb1}>
            <ReqStatusBadge status={RequisitionStatus.pendingThirdApproval} />
          </Col>
        </Row>
      </DevToolsSection>

      <DevToolsSection title="Inputs">
        <Row>
          <Col xs={4}>
            <h3>Checkboxes</h3>
            <Checkbox id="checkbox-unchecked" name="checkbox-unchecked" label="Checkbox - unchecked" />
            <br />
            <Checkbox id="checkbox-checked" name="checkbox-checked" label="Checkbox - checked" checked onChange={() => {}} />
            <br />
            <Checkbox id="checkbox-disabled" name="checkbox-disabled" label="Checkbox - disabled" checked disabled />
          </Col>

          <Col xs={4} className="input-form">
            <h3>Autocomplete</h3>
            <Autocomplete
              id="autocomplete-example"
              data-testid="autocomplete-example"
              label="Example with lots of options"
              options={autocompleteOptions}
              value={null}
              onChange={() => {}}
              onBlur={() => {}}
            />
            <Autocomplete
              direction="up"
              id="autocomplete-example-direction"
              data-testid="autocomplete-example-direction"
              label="Example that opens above"
              options={autocompleteOptions}
              value={null}
              onChange={() => {}}
              onBlur={() => {}}
            />
          </Col>
        </Row>
      </DevToolsSection>

      <DevToolsSection title="Callout">
        <Callout title="Canada Facts">
          Canada has a population of 37.59 million! Its capitol is Ottawa.
        </Callout>
        <br />
        <Callout title="Canada Facts" titleClass={joinArgs(utilClasses.backgroundNearBlack, utilClasses.textTrueWhite)}>
          Canada has a population of 37.59 million! Its capitol is Ottawa.
        </Callout>
      </DevToolsSection>

      <DevToolsSection title="Status Indication">
        <h4>Loading spinner:</h4>
        <LoadingSpinner />
      </DevToolsSection>

      <DevToolsSection title="Buttons">
        <p>Button like styles are applied through a series of `className`s and can be applied to any element to make it appear like a button.</p>
        <p>
          The <code>`useButtonStyles`</code> hook lives within our Theme directory: <code>`src/Themes/button.styles.ts`</code> { /* eslint-disable-line */}
        </p>

        <h4>Base:</h4>
        <p><strong>Example:</strong></p>
        <button type="button" className={joinArgs(buttonClasses.base, buttonClasses.primaryIntent)}>Base Button</button>
        <p><strong>Code:</strong></p>
        <code>
          {'<button type="button" className={joinArgs(buttonClasses.base, buttonClasses.primaryIntent)}>Base Button</button>'}
        </code>
        <hr />

        <h4>Reversed:</h4>
        <p><strong>Example:</strong></p>
        <button type="button" className={joinArgs(buttonClasses.base, buttonClasses.reverse)}>Reversed Button</button>
        <p><strong>Code:</strong></p>
        <code>
          {'<button type="button" className={joinArgs(buttonClasses.base, buttonClasses.reverse)}>Reversed Button</button>'}
        </code>
        <hr />

        <h4>Destructive:</h4>
        <p><strong>Example:</strong></p>
        <button type="button" className={joinArgs(buttonClasses.base, buttonClasses.dangerIntent)}>Destructive Button</button>
        <p><strong>Code:</strong></p>
        <code>
          {'<button type="button" className={joinArgs(buttonClasses.base, buttonClasses.destructive)}>Destructive Button</button>'}
        </code>
        <hr />

        <h4>Text:</h4>
        <p><strong>Example:</strong></p>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <button type="button" className={joinArgs(buttonClasses.base, buttonClasses.text)}>Text Button</button>
          <button type="button" className={joinArgs(buttonClasses.base, buttonClasses.text, buttonClasses.leadingIcon)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            With leading icon
          </button>
          <button type="button" className={joinArgs(buttonClasses.base, buttonClasses.text, buttonClasses.trailingIcon)}>
            With trailing icon
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
        <p><strong>Code:</strong></p>
        <code>
          {'<button type="button" className={joinArgs(buttonClasses.base, buttonClasses.text)}>Text Button</button>'}
        </code>
        <hr />

        <h4>Leading Icon:</h4>
        <p><strong>Example:</strong></p>
        <button type="button" className={joinArgs(buttonClasses.base, buttonClasses.reverse, buttonClasses.leadingIcon)}>
          <FontAwesomeIcon icon={faPastafarianism} />
          Leading Icon
        </button>
        <p><strong>Code:</strong></p>
        <code>
          {`<button type="button" className={joinArgs(buttonClasses.base, buttonClasses.reverse, buttonClasses.leadingIcon)}>
  <FontAwesomeIcon icon={faPastafarianism} />
  Leading Icon
</button>`}
        </code>
        <hr />

        <h4>Trailing Icon:</h4>
        <p><strong>Example:</strong></p>
        <button type="button" className={joinArgs(buttonClasses.base, buttonClasses.reverse, buttonClasses.trailingIcon)}>
          Trailing Icon
          <FontAwesomeIcon icon={faHeadSideMask} />
        </button>
        <p><strong>Code:</strong></p>
        <code>
          {`<button type="button" className={joinArgs(buttonClasses.base, buttonClasses.reverse, buttonClasses.trailingIcon)}>
  Trailing Icon
  <FontAwesomeIcon icon={faHeadSideMask} />
</button>`}
        </code>
        <hr />

        <h4>Disabled:</h4>
        <p><em>Disabled buttons can be handled with an attribute</em></p>
        <p><strong>Example:</strong></p>
        <button disabled type="button" className={joinArgs(buttonClasses.base)}>
          Disabled w/ Attribute
        </button>

        <p><strong>Code:</strong></p>
        <code>
          {`<button disabled type="button" className={joinArgs(buttonClasses.base)}>
  Disabled w/ Attribute
</button>`}
        </code>
        <hr />

        <h4>Anchor with Button Appearance:</h4>
        <p><strong>Example:</strong></p>
        <a
          className={joinArgs(buttonClasses.base, buttonClasses.primaryIntent)}
          href={routes.devTools.index}
        >
          link that looks like button
        </a>
        <p><strong>Code:</strong></p>
        <code>
          {`<a
  className={joinArgs(buttonClasses.base, buttonClasses.primaryIntent)}
  href="${routes.devTools.index}"
>
  link that looks like button
</a>`}
        </code>
      </DevToolsSection>
    </>
  );
};
