import { FC } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PageHeader } from '../../Shared/PageHeader/PageHeader';
import { dictionary } from '../../dictionary';
import useUtilityStyles from '../../Themes/utility.styles';
import { routes } from '../../Utils/routes';
import { Button } from '../../Shared/buttons/Button';
import { joinArgs } from '../../Utils/arrayUtils';
import { If } from '../../Shared/If';
import { currentUserRoleSelector } from '../../redux/user/userSelectors';
import { userRoles } from '../../Utils/userRoles';

export const HelpCenter: FC = () => {
  const utilClasses = useUtilityStyles();
  const history = useHistory();
  const navToEditTerms = () => history.push(routes.helpCenter.editTermsOfUse);
  const currentUserRole = useSelector(currentUserRoleSelector);
  const isOmiSupport = currentUserRole === userRoles.omiAdmin;

  return (
    <>
      <PageHeader title={dictionary.HELP_CENTER_HEADER} />
      <Row>
        <Col xs={12} className={joinArgs(utilClasses.flex, utilClasses.alignCenter)}>
          <h2>{dictionary.TERMS_OF_USE_HEADER}</h2>
          <If condition={isOmiSupport}>
            <Button className={utilClasses.ml5} buttonStyle="reverse" onClick={navToEditTerms}>{dictionary.HELP_CENTER_MANAGE_BUTTON}</Button>
          </If>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <div><Link to={routes.helpCenter.termsOfUse}>{dictionary.TERMS_OF_USE_HEADER}</Link></div>
              <div className={utilClasses.mt2}>
                <a href="https://www.owens-minor.com/privacy-policy/" rel="noopener noreferrer" target="_blank">
                  {dictionary.PRIVACY_POLICY_LABEL}
                </a>
              </div>
            </Col>
          </Row>

          <h2>{dictionary.ADDITIONAL_ASSISTANCE_HEADER}</h2>
          <p>{dictionary.ADDITIONAL_ASSISTANCE_DETAILS}</p>
          <Row className={utilClasses.mt2}>
            <Col xs={3}>
              <div><strong>Email:</strong></div>
              <div>support@qsight.net</div>
            </Col>
            <Col xs={3}>
              <div><strong>Phone:</strong></div>
              <div>1-877-774-4488 (1-877-QSIGHT8)</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
